import auth from '@/utils/auth.js'

export default {
    setUser: function(val) {
        auth.setUser(val)
    },
    setUser2: function(val) {
        auth.saveUserToCookie(val)
    },
    getUser: function() {
        return auth.getUser()
    },
    getUser2: function() {
        return auth.getUserFromCookie()
    },
    clearUser: function() {
        auth.removeUser()
        auth.clearUserFromCookie()
    }
}