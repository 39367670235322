import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from 'axios'
import request from '@/utils/httpapi.js'
import util from '@/utils/utils.js'
import user from '@/utils/user.js'
import vant from 'vant';
import '@/utils/vant.css'

// 利用use函数加载路由
const app = createApp(App);
app.use(router);
app.use(vant);
app.provide('axios', axios);
app.provide('request', request);
app.provide('util', util);
app.provide('user', user);
app.mount('#app');