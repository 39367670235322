import Cookies from 'js-cookie'

const TokenKey = 'chat-token'
const UserKey = 'chat-user'

export default {
    getToken: function() {
        return sessionStorage.getItem('chat-token')
    },
    setToken: function(token) {
        return sessionStorage.setItem('chat-token', token)
    },
    removeToken: function() {
        return Cookies.remove(TokenKey)
    },
    getUser: function() {
        var usr = sessionStorage.getItem(UserKey)
        if (usr) { return JSON.parse(usr) } else { return null }
    },
    setUser: function(user) {
        return sessionStorage.setItem(UserKey, JSON.stringify(user))
    },
    removeUser: function() {
        return sessionStorage.removeItem(UserKey)
    },
    saveUserToCookie(user) {
        Cookies.set(UserKey, JSON.stringify(user), { expires: 7 })
    },
    getUserFromCookie() {
        var cookie = Cookies.get(UserKey)
        if (cookie)
            return JSON.parse(cookie)
        else
            return null
    },
    clearUserFromCookie() {
        Cookies.remove(UserKey)
    }
}