export default {
    // eslint-disable-next-line space-before-function-paren
    guid: function() {
        return (this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4())
    },
    // eslint-disable-next-line space-before-function-paren
    S4: function() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    // eslint-disable-next-line space-before-function-paren
    formatDate: function(date, type) {
        var month = `${date.getMonth() + 1}`
        var day = `${date.getDate()}`
        var hour = `${date.getHours()}`
        var minutes = `${date.getMinutes()}`

        month = month < 10 ? ('0' + month) : month
        day = day < 10 ? ('0' + day) : day
        hour = hour < 10 ? ('0' + hour) : hour
        minutes = minutes < 10 ? ('0' + minutes) : minutes

        if (type == 1) {
            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else if (type == 2) {
            return `${date.getFullYear()}-` + month + `-` + day + ` ` + hour + `:` + minutes + `:00`
        } else if (type == 3) {
            return `${date.getFullYear()}-` + month
        }
    }
}