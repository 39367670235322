<style scoped>
*{font-size: 16px;overflow: hidden;word-break: break-all;font-family:'Microsoft Yahei';scrollbar-width:0px;}
.header{height: 40px;display: flex;background-color: #ffffff;align-items: center;border-bottom: 1px solid #dadada;}
.header .logo{display: flex;flex-shrink: 0;align-items: center;padding-left: 10px;color: #462805;}
.header .logo img{height: 20px;padding-right: 5px;}
.header .center{display: flex;align-items: center;flex-grow: 1;justify-content: center;}
.header .center img{height: 20px;padding-right: 5px;}
.header .func{display: flex;flex-grow: 1;align-items: center;flex-direction: row-reverse;}
.header .func label{padding-right: 15px;}
.header .func .item:hover{cursor: pointer;font-weight: bold;}
.header .func2{display: flex;;align-items: center;flex-direction: row-reverse;flex-shrink: 0;}
.header .func2 label{padding-right: 15px;}
.main{width: 100vw;height: calc(100vh - 41px);display: flex;}
.main .left{width: 200px;background-color: #ebe8eb;border-right: 1px solid #e2e0f4;padding: 10px;flex-shrink: 0;}
.left .find{display: flex;align-items: center;border-bottom: 1px solid #dadada;background-color: transparent;padding: 10px 0;}
.left .find input[type='text']{border:0;background-color: transparent;font-size: 12px;flex-grow: 1;}
.left .find .query_btn{font-size: 10px;flex-shrink: 0;background-color:#f1f1f1;border:1px solid #dadada;padding:3px 10px;border-radius: 3px;cursor:pointer}
.left .records{overflow-y: auto;height:calc(100vh - 112px);}
.left .record{display: flex;align-items: center;padding:10px 5px;margin:5px 0;padding-left: 10px;cursor:pointer}
.left .record:hover{background-color: #fdfcfd;border-radius: 10px;}
.left .now_record{background-color: #fdfcfd;border-radius: 10px;}
.left .record img{width: 16px;flex-shrink: 0;margin-right: 8px;}
.left .record .msg{overflow: hidden;font-size: 12px;height: 25px;line-height: 25px;}

.main .right{padding:10px 30px;display: flex;flex-direction: column;flex-grow: 1;}
.right .page{flex-grow: 1;overflow-y: auto;}
.right .inputs{flex-shrink: 0;display: flex;flex-direction: column;padding-bottom: 10px;}
.inputs .ops{display: flex;color: #ff0000;padding: 5px 0;}
.inputs .texts{display: flex;border:1px solid #462805;border-radius: 5px;padding:5px;background-color: #fff;align-items: flex-end;}
.inputs textarea{height: 60px;resize:none;flex-grow: 1;font-size: 14px;outline-color:#fff;border:0;color:#333;}
.main_btn{background: -webkit-linear-gradient(left, #8a8df7, #4772ea);cursor: pointer;font-size: 13px;border-radius: 10px;height: 30px;line-height: 30px;border: 0;margin: 0;padding: 0;text-align: center;color: #fff;padding:0 15px;}
.main_btn:hover{background: -webkit-linear-gradient(left, #7484f3, #2852cb);}
.main_btn2{display: flex;align-items: center;background: -webkit-linear-gradient(left, #8a8df7, #4772ea);cursor: pointer;font-size: 13px;border-radius: 5px;height: 30px;line-height: 30px;border: 0;margin: 0;padding: 0;text-align: center;color: #fff;padding:0 15px;margin-right: 10px;flex-shrink: 0;}
.main_btn2:hover{background: -webkit-linear-gradient(left, #7484f3, #2852cb);cursor: pointer;}
.btn_red{background: -webkit-linear-gradient(left, #c45151, #c62f2f)}
.btn_red:hover{background: -webkit-linear-gradient(left, #e93d3d, #e61d1d)}
.main_btn2 img{cursor: pointer;margin-right: 6px;}
.main_btn2 label{cursor: pointer;font-size: 14px;}
.start{text-align:center;}
.start .title{line-height: 30px;font-weight: bold;padding:20px 0;font-size: 20px;;}
.start .models{max-width:500px;display: flex;flex-wrap: wrap;justify-content: center;}
.start .models .model{display: flex;align-items: center;padding: 8px 10px;border:1px solid #dadada;color:#555;border-radius: 5px;margin-right: 10px;cursor: pointer;padding-left: 35px;margin-bottom: 10px;}
.start .models .choiced{border:1px solid #000;color:#000;}
.start .models .limit{background-color: #dadada;border-radius: 5px;font-size: 12px;width:60px;margin-left: 10px;}
.start .fasts div{margin-right: 10px;font-size: 14px;padding: 5px 10px;color:#000;}
.start .fasts .fast{background-color: #f6f8fd;cursor: pointer;border-radius: 5px;}
.page .talk{display: flex;flex-direction: column;}
.page .user{font-weight: bold;}
.page .content{display: flex;margin-bottom: 20px;}
.page .content .name{width: 23px;height:23px;background-color: #462805;text-align: center;line-height: 23px;border-radius: 5px;flex-shrink: 0;margin-right: 10px;color:#fff;font-size: 12px;}
.page .content .head{width: 23px;height:23px;flex-shrink: 0;margin-right: 10px;}
.page .content .head img{width: 23px;height:23px;border-radius: 5px;}
.page .content .msg{flex-grow: 1;}
.page .content .generating_alert{font-size: 12px;color:#555;}
.page .content .stop{font-size: 12px;cursor: pointer;}
.page .content .stopByError{font-size: 12px;cursor: pointer;margin-top: 5px;}
::-webkit-scrollbar { width: 0px; display: none;}
::-webkit-scrollbar-thumb { -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);background-color:#f3f3f3;}
::-webkit-scrollbar-track-piece {width: 2px}
input[type="text"]:focus{ outline: none; }
.loader {border: 2px solid #ebe8eb;border-top: 2px solid #2852cb;border-radius: 50%;width: 20px;height: 20px;animation: spin 1s linear infinite;}
@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}
.div_phone_login .line{padding: 10px 0;text-align:left;align-items: center;}
.div_phone_login .line input[type='number'],.div_phone_login .line input[type='text']{height: 30px;line-height: 30px;border-radius: 5px;border:1px solid #dadada;font-size: 13px;text-indent: 5px;}
.div_phone_login .line input[type='button']{height: 30px;line-height: 30px;border-radius: 5px;border:1px solid #dadada;font-size: 13px;margin: 0;padding:0;}
.div_phone_login .line input[type='button']:hover{background-color: #dadada;}
</style>
<template>
  <div @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <div v-if="from!='pm'" class="header" style="display: flex;">
      <div class="logo">
        <img v-if="deviceModel==2" @click="showMenu" src="@/assets/history.png">
        <img v-if="deviceModel==1" src="@/assets/logo.png">
        <div v-if="deviceModel==1">海星AI</div>
      </div>
      <div class="center" v-if="deviceModel==2">
        <img src="@/assets/logo.png">
        <div>海星AI</div>
      </div>
      <div :class="deviceModel==1?`func`:`func2`">
        <label v-if="from==''" class="item" @click="logOut">退出</label>
        <label v-if="deviceModel==1">{{ chatUserInfo.roleName }}</label>
        <label>{{ chatUserInfo.nickName }}</label>
      </div>
    </div>
    <div class="main" :style="from=='pm'?`height:100vh;`:``">
      <div class="left" v-if="deviceModel==1||isShowMenu" :style="deviceModel==2?`position:absolute;width:70%`:``">
        <div class="find" v-if="chatUser.name">
          <img src="@/assets/find.png" style="width: 16px;flex-shrink: 0;margin-right: 10px;" />
          <input type="text" v-model="queryBag.queryKey" placeholder="查找记录" @keyup="loadRecords" />
          <!-- <div v-on:click="loadRecords" class="query_btn">搜索</div> -->
        </div>
        <div class="records" :style="`padding-top: 10px;`+(from=='pm'?`height:calc(100vh - 71px);`:``)">
          <div :class="`record `+(itemIndex==choicedMsgIndex?`now_record`:``)" v-for="(item,itemIndex) in queryResult.list" v-bind:key="itemIndex" @click="changeDialog(itemIndex)">
            <img :src="getImgs(item.apiModel)">
            <div class="msg">{{ item.msgTitle }}</div>
          </div>
        </div>
      </div>
      <div v-if="pageState=='chat'" class="right" @click="rightClick" :style="deviceModel==2?`padding:10px 10px`:``">
        <div class="page" ref="divMsgList">
          <div :class="`talk`+(item.role==1?` user`:` ai`)" v-for="(item,itemIndex) in msgList" v-bind:key="itemIndex">
            <div class="content">
              <div class="name" v-if="item.role==1">{{chatUserInfo.nickName.substring(0,1)}}</div>
              <div class="head" v-else-if="choicedModelIndex>-1"><img :src="getImgs(modelList[choicedModelIndex].modelId)" /></div>
              <div class="head" v-else><img :src="getImgs(curHistoryApiModel)" /></div>
              <div class="msg" :style="deviceModel==2?`max-width:85%`:(`max-width:`+msgWidth+`;`)">
                <div class="msg2" :style="item.role==2?`line-height: 20px;font-size: 14px;`:`line-height: 25px;font-size: 15px;`" v-html="item.html? item.html : item.msg || ''"></div>
                <div v-if="item.role==2&&generating&&itemIndex==msgList.length-1" class="loader"></div>
                <div v-if="autoStop&&itemIndex==msgList.length-1" class="stopByError" @click="reSend">AI处理失败，点击重新发送</div>
                <!-- <div class="generating_alert" v-if="item.role==2&&generating&&itemIndex==msgList.length-1"
                            :style="item.msg.length==0?``:`padding-top:10px;`">
                                <label class="stop" @click="stop">生成中，停止生成</label>
                            </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="inputs">
          <div class="ops">
            <div v-if="!generating" class="main_btn2" @click="createDialog">
              <img src="@/assets/add.png" style="width: 10px;">
              <label>新建对话</label>
            </div>
            <div v-else class="main_btn2 btn_red" @click="stop">
              <img src="@/assets/stop.png" style="width: 10px;">
              <label>停止生成</label>
            </div>
          </div>
          <div class="texts">
            <textarea v-model="text" :placeholder="placeHolder"></textarea>
            <div v-if="text&&!generating" @click="completions" class="main_btn">发送</div>
          </div>
        </div>
      </div>
      <div v-else-if="pageState=='start'" class="right" @click="rightClick">
        <div class="start_container" style="display: flex;justify-content: center;">
          <div class="start" :style="deviceModel==1?`padding-top: 80px;`:`padding-top: 20px;`">
            <div v-if="deviceModel==1"><img src="@/assets/logo.png" style="width: 60px;" /></div>
            <div class="title" style="padding-bottom: 0;">欢迎使用海星AI</div>
            <div class="title" style="font-size: 14px;padding-top: 0;font-weight: normal;">模型消耗次数每天清零</div>
            <div class="models">
              <div :class="item.choiced==1?`model choiced`:`model`" @click="changeModel(itemIndex)" v-for="(item,itemIndex) in chatUserInfo.modelList" v-bind:key="itemIndex" :style="`background: url('`+getImgs(item.modelId)+`') no-repeat left 10px center;background-size:20px auto;`">
                <div style="width: 100px;text-align: left;">{{ item.modelName }}</div>
                <div v-if="item.dayLimit>0" class="limit">{{ item.dayUsed }} / {{ item.dayLimit }}</div>
                <div v-else class="limit"></div>
              </div>
            </div>
            <div class="fasts" style="margin-top: 40px;display: flex;flex-wrap: wrap;height: 28px;overflow: hidden;;">
              <div style="margin-right: 0px;padding-right: 5px;">快速尝试：</div>
              <div class="fast" v-for="(fast,fastIndex) in fastList" v-bind:key="fastIndex" @click="text=fast.content">
                {{ fast.title }}
              </div>
            </div>
            <!-- <div v-if="deviceModel==1" class="tools" style="margin-top: 20px;display: flex;flex-wrap: wrap;overflow: hidden;align-items: center;">
                <div style="font-size: 14px;padding: 5px 10px;color:#000;margin-right: 5px;padding-right: 5px;">辅助工具：</div>
                <van-uploader :after-read="afterExcelRead" accept=".xlsx">
                    <van-button icon="plus" type="default" size="mini">上传Excel</van-button>
                </van-uploader>
            </div> -->
            <div class="inputs" style="margin-top: 20px;">
              <div class="texts" style="flex-shrink: 0;">
                <textarea v-model="text" :placeholder="placeHolder"></textarea>
                <div v-if="text" @click="start" class="main_btn">发送</div>
              </div>
              <div v-if="text&&deviceModel==1" style="text-align: right;font-size: 12px;height: 30px;line-height: 30px;color: #555;">使用 [回车] 换行，[Ctrl+回车] 发送</div>
            </div>
            <div v-if="deviceModel==2" @click="startAudio">asdf12sadf3</div>
          </div>
        </div>
      </div>
      <div v-else class="right" @click="rightClick">
        <div class="start_container" style="display: flex;justify-content: center;align-items: center;height:100%;">
          <div class="start">
            <div v-if="!isLoging&&chatUser.name==''">
              <div v-if="from!=''">登录已失效<a href='http://hxqdzs.heteen.com/j/auth?from=chat' style="margin-left: 10px;">点击重新登录</a></div>
              <div v-else>
                <div class="div_phone_login" style="padding: 20px 30px;background-color: #fff;border-radius: 10px;">
                  <div style="text-align: center;font-weight: bold;padding: 20px 0;;">请登录</div>
                  <div class="line" style="display:flex;">
                    <div style="flex-shrink: 0;width:70px;padding-right:10px;">手机号码</div>
                    <input :type="deviceModel==1?`text`:`number`" v-model="loginBody.phone" placeholder="请输入手机号" style="flex-grow: 1;">
                  </div>
                  <div class="line" style="display:flex;">
                    <div style="flex-shrink: 0;width:70px;padding-right:10px;">验证码</div>
                    <input :type="deviceModel==1?`text`:`number`" v-model="loginBody.code" placeholder="请输入短信验证码" style="flex-grow: 1;" />
                    <input type="button" @click="sendCode" :value="(sendCodeTimes==0?'发送':sendCodeTimes)" style="flex-shrink: 0;margin-left: 10px;width:50px;cursor: pointer;" />
                  </div>
                  <van-button type="primary" block style="margin:20px 0 30px 0;" @click="phoneLogin" :loading="phoneLoging">立即登录</van-button>
                </div>
              </div>
            </div>
            <div v-else>登录中</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from 'vue'
import axios from 'axios'
import SSE from '@/utils/sse.js'
import marked from '@/utils/marked.min.js'
import '@/utils/tomorrow-night.min.css'
import hljs from '@/utils/highlight.min.js'
import RecorderManager from '@/utils/index.umd.js'

import { showToast, Toast, showLoadingToast, showSuccessToast, showFailToast, Icon, Uploader, Button, Field, CellGroup } from 'vant'

let iatWS;
let countdownInterval;
var exmLine=2;

export default {
  data() {
    return {
      isLoging: true,
      user: null,
      request: null,
      placeHolder: '输入你想问的问题，使用 [回车] 换行，[Ctrl+回车] 发送消息',
      loginBody: { phone: '', code: '' },
      chatUser: { name: '', key: '' },
      chatUserInfo: { nickName: '', roleId: 0, roleName: '', modelList: [] },
      askNo: '',
      answers: '',
      urlHead: 'https://aichat-api.heteen.com/',
      // urlHead: 'http://localhost:8085/',
      text: '',
      generating: false,
      msgList: [],
      pageState: '',
      modelList: [],
      choicedModelIndex: 0,
      queryBag: { pageIndex: 1, pageSize: 20, queryKey: '' },
      queryResult: { rowCount: 0, list: [] },
      choicedMsgIndex: -1,
      deviceModel: 1,
      isShowMenu: false,
      replyingTimer: null,
      replyingTimes: 0,
      lastReplyedAnswer: '',
      autoStop: false,
      fastList: [],
      msgWidth: '',
      from: '',
      sendCodeTimer: null,
      sendCodeTimes: 0,
      phoneLoging: false,
      aliVoiceToken:'',
      recorder:null,
      aliMsgId:'',
      aliTaskId:'',
      isPauseRecord:false,
      btnStatus: 'UNDEFINED',
      curHistoryApiModel:-1
    };
  },
  created(){
    
      this.recorder = new RecorderManager("../../dist");

      this.recorder.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
        if (iatWS.readyState === iatWS.OPEN) {
            iatWS.send(new Int8Array(frameBuffer));
            if (isLastFrame) {
                iatWS.send('{"end": true}');
                this.changeBtnStatus("CLOSING");
            }
        }
    };
    this.recorder.onStop = () => {
        clearInterval(countdownInterval);
        let actions = {
            "header": {
                "message_id": this.aliMsgId,
                "task_id": this.aliTaskId,
                "namespace": "SpeechTranscriber",
                "name": "StopTranscription",
                "appkey": 'Mp5ZEltQMa7cEkHg',
            }
        };
        iatWS.send(JSON.stringify(actions));
    };
  },
  mounted() {
    marked.setOptions({
      renderer: new marked.Renderer(),
      highlight: function (code) {
        return hljs.highlightAuto(code).value;
      },
      langPrefix: 'hljs language-',
      pedantic: false,
      gfm: true,
      breaks: false,
      sanitize: false,
      smartypants: false,
      xhtml: false
    });
    this.user = inject('user')
    this.request = inject('request')
    const query = new URLSearchParams(window.location.search);
    var from = query.get('from') ? query.get('from') : ''
    this.from = from
    if (!this.user.getUser()) {
      if (this.from != '') {
        var token = query.get('token') ? query.get('token') : ''
        if (token) {
          var url = this.urlHead + '/auth/getWWAccount'
          let _this = this
          this.request({ 'url': url, 'data': { token: token } }).then(function (res) {
            _this.isLoging = false
            if (res.code == 0) {
              _this.user.setUser(res.data)
              _this.chatUser = res.data
              _this.load()
            }
          })
        }
      } else {
        if (this.user.getUser2()) {
          var usr = this.user.getUser2()
          this.user.setUser(usr)
          this.chatUser = usr
          this.load()
        }
        this.isLoging = false;
      }
    } else {
      this.chatUser = this.user.getUser()
      this.isLoging = false
      this.load()
    }

    this.keyupSubmit()
    // this.onUnload()

    var agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("windows") == -1 && agent.indexOf("macintosh") == -1) {
      this.deviceModel = 2
    }
  },
  methods: {
    startAudio(){
        initAudio()
        // this.getAliVoiceToken()
        this.startRecording()
    },
    startRecording(){
        if (this.btnStatus === "UNDEFINED" || this.btnStatus === "CLOSED") {
            this.connectWebSocket();
        } else if (this.btnStatus === "CONNECTING" || this.btnStatus === "OPEN") {
            // 结束录音
            this.recorder.stop();
        }
    },
    getWebSocketUrl() {
        this.aliVoiceToken='e3ea90b4f18545ed93d53420976e17e0';
        var url = "wss://nls-gateway.cn-shanghai.aliyuncs.com/ws/v1?token="+this.aliVoiceToken;
        return url;
    },
    connectWebSocket(){
        const websocketUrl = this.getWebSocketUrl();
        // console.log(websocketUrl);
        if ("WebSocket" in window) {
            iatWS = new WebSocket(websocketUrl);
        } else if ("MozWebSocket" in window) {
            iatWS = new MozWebSocket(websocketUrl);
        } else {
            alert("浏览器不支持WebSocket");
            return;
        }

        this.changeBtnStatus("CONNECTING");
        iatWS.onopen = (e) => {
            this.aliMsgId = this.getRandomStrNum();
            this.aliTaskId = this.getRandomStrNum();
            let actions = {
                "header": {
                    "message_id": this.aliMsgId,
                    "task_id": this.aliTaskId,
                    "namespace": "SpeechTranscriber",
                    "name": "StartTranscription",
                    "appkey": 'Mp5ZEltQMa7cEkHg',
                },
                "payload": {
                    "format": "PCM",
                    "sample_rate": 16000,
                    "enable_intermediate_result": true,
                    "enable_punctuation_prediction": true,
                    "enable_inverse_text_normalization": true,
                    "enable_ignore_sentence_timeout":true,
                    "enable_words":true,
                    "max_sentence_silence":500,
                }
            };
            iatWS.send(JSON.stringify(actions));

            // 开始录音
            this.recorder.start({
                sampleRate: 16000,
                frameSize: 1280,
            });
            this.changeBtnStatus("OPEN");
        };
        iatWS.onmessage = (e) => {
            this.renderResult(e.data);
        };
        iatWS.onerror = (e) => {
            clearInterval(this.timer);
            console.error(e);
            this.recorder.stop();
            this.changeBtnStatus("CLOSED");
            console.log(e);
        };
        iatWS.onclose = (e) => {
            this.recorder.stop();
            this.changeBtnStatus("CLOSED");
        };
    },
    renderResult(resultData){
        let jsonData = JSON.parse(resultData);
        if (jsonData.header.name == "TranscriptionStarted") {
        } else if (jsonData.header.name == "SentenceEnd") {
            const data = jsonData.payload;
            this.text+=data.result;
        }
        else if (jsonData.header.name == "TaskFailed") {
            var data = jsonData.header;
            if (data.status_text != "Gateway:MESSAGE_INVALID:Missing message header!") {
                this.showError(data.status_text);
                console.log(jsonData)
            }
        }
    },
    changeBtnStatus(status,doMore) {
        this.btnStatus = status;
        if (status === "CONNECTING"||status=="STARTBYTEXT") {
            this.clearRecording(status);
        } else if (status === "OPEN") {
        } else if (status === "CLOSING") {
        } else if (status === "CLOSED") {
        } else if (status == "ENDING") {
        }
    },
    clearRecording(fromWhere){

    },
    getRandomStrNum() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 32; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23];

        var uuid = s.join("");
        return uuid;
    },
    getAliVoiceToken(){
        var url = this.urlHead + '/user/getVoiceToken'
        let _this = this
        this.request({ 'url': url , 'data': {}}).then(function (res) {
          if (res.code == 0) {
            _this.aliVoiceToken = res.data
            console.log(_this.aliVoiceToken)
          } else {
            showFailToast({ message: res.data, icon: 'fail' });
          }
        })
    },
    logOut() {
      this.loginBody = { phone: '', code: '' }
      this.user.clearUser()
      this.chatUser = { name: '', key: '' }
      this.chatUserInfo = { nickName: '', roleId: 0, roleName: '', modelList: [] }
      this.msgList = []
      this.queryResult = { rowCount: 0, list: [] }
      this.pageState = ''
    },
    sendCode() {
      if (this.sendCodeTimes == 0) {
        if (!this.loginBody.phone) {
          showFailToast({ message: '请输入手机号', icon: 'fail' });
          return;
        }
        var url = this.urlHead + '/auth/sendLoginCode'
        let _this = this
        this.request({ 'url': url, 'data': this.loginBody }).then(function (res) {
          if (res.code == 0) {
            showToast('验证码已下发');
            _this.sendCodeTimes = 30;
            _this.sendCodeTimer = setInterval(_this.sendCodeAutoOpen, 1000);
          } else {
            showFailToast({ message: res.data, icon: 'fail' });
          }
        })
      }
    },
    sendCodeAutoOpen() {
      if (this.sendCodeTimes > 0) {
        this.sendCodeTimes--;
      } else
        clearInterval(this.sendCodeTimer)
    },
    phoneLogin() {
      if (!this.phoneLoging) {
        if (!this.loginBody.phone) {
          showFailToast({ message: '请输入手机号', icon: 'fail' });
          return;
        }
        if (!this.loginBody.code) {
          showFailToast({ message: '请输入验证码', icon: 'fail' });
          return;
        }
        this.phoneLoging = true;
        var url = this.urlHead + '/auth/phoneLogin'
        let _this = this
        this.request({ 'url': url, 'data': this.loginBody }).then(function (res) {
          if (res.code == 0) {
            _this.user.setUser2(res.data)
            _this.user.setUser(res.data)
            _this.chatUser = res.data
            _this.load()
            _this.phoneLoging = false
          } else {
            showFailToast({ message: res.data, icon: 'fail' });
            _this.phoneLoging = false
          }
        })
      }
    },
    handleTouchStart(event) {
      if (this.deviceModel == 2) {
        this.startX = event.touches[0].clientX;
        this.startY = event.touches[0].clientY;
      }
    },
    handleTouchMove(event) {
      if (this.deviceModel == 2) {
        this.endX = event.touches[0].clientX;
        this.endY = event.touches[0].clientY;
        if (this.endX - this.startX >= 100) {
          this.isShowMenu = true;
        } else if (this.startX - this.endX >= 100) {
          this.isShowMenu = false;
        }
      }
    },
    handleTouchEnd() {
    },
    rightClick() {
      if (this.deviceModel == 2) {
        this.isShowMenu = false
      }
    },
    showMenu() {
      if (this.deviceModel == 2)
        this.isShowMenu = !this.isShowMenu
    },
    load() {
      if (this.chatUser.name != '') {
        this.changePageStatus('start')
        this.loadRecords()
        this.loadFast()
      }
      this.sizeChanged()
    },
    test() {
      var url = this.urlHead + '/user/test'
      let _this = this
      this.request({ 'url': url, 'data': {} }).then(function (res) {
        if (res.code == 0) {
          console.log(1)
        } else {
          showFailToast({ message: res.data, icon: 'fail' });
        }
      })
    },
    createDialog() {
      this.msgList = [];
      this.choicedMsgIndex = -1
      this.choicedModelIndex = 0
      this.changePageStatus('start')
    },
    changeDialog(index) {
      if (this.generating == true) {
        this.stop()
      }

      this.clearAllStateChanged()

      this.choicedMsgIndex = index
      var url = this.urlHead + '/user/getRecordsMsgList'
      let _this = this
      this.curHistoryApiModel = this.queryResult.list[index].apiModel
      this.request({ 'url': url, 'data': { 'no': this.queryResult.list[index].askNo } }).then(function (res) {
        if (res.code == 0) {
          _this.showOldMsg(res.data)
          _this.showMenu()
        } else {
          showFailToast({ message: res.data, icon: 'fail' });
        }
      })
    },
    showOldMsg(res) {
      var list = res;
      for (var j = 0; j < list.length; j++) {
        list[j].html = marked.marked(list[j].msg)
      }
      this.msgList = list;
      document.querySelectorAll('code').forEach((block) => {
        if (!block.classList.contains('hljs')) {
          block.classList.add('hljs')
        }
      });
      var choicedMsg = this.queryResult.list[this.choicedMsgIndex]
      this.askNo = choicedMsg.askNo
      var modelIndex = -1
      for (var i = 0; i < this.modelList.length; i++) {
        if (this.modelList[i].modelId == choicedMsg.apiModel) {
          modelIndex = i
          break
        }
      }
      this.choicedModelIndex = modelIndex;
      this.text = ''
      this.changePageStatus('chat')
      this.scrollToEnd()
    },
    loadFast() {
      var url = this.urlHead + '/user/getFastQuestions'
      let _this = this
      this.request({ 'url': url, 'data': {} }).then(function (res) {
        if (res.code == 0) {
          _this.fastList = res.data
        } else {
          showFailToast({ message: res.data, icon: 'fail' });
        }
      })
    },
    loadRecords() {
      var url = this.urlHead + '/user/getRecords'
      let _this = this
      this.request({ 'url': url, 'data': this.queryBag }).then(function (res) {
        if (res.code == 0) {
          _this.queryResult.rowCount = res.rowCount
          _this.queryResult.list = res.data
        } else {
          showFailToast({ message: res.data, icon: 'fail' });
        }
      })
    },
    stop() {
      this.generating = false
      var url = this.urlHead + '/chat/stop'
      let _this = this
      this.request({ 'url': url, 'data': { no: this.askNo } }).then(function (res) {
        if (res.code == 0) {
          console.log('stop')
        } else {
          showFailToast({ message: res.data, icon: 'fail' });
        }
      })
    },
    changePageStatus(status) {
      this.clearAllStateChanged()
      if (status == 'start') {
        var url = this.urlHead + '/user/getUserInfo'
        let _this = this
        this.request({ 'url': url, 'data': {} }).then(function (res) {
          if (res.code == 0) {
            _this.chatUserInfo = res.data
            if (!_this.chatUserInfo.nickName || _this.chatUserInfo.nickName == '')
              _this.chatUserInfo.nickName = _this.chatUser.name
            _this.modelList = _this.chatUserInfo.modelList
            _this.pageState = status
          } else {
            showFailToast({ message: res.data, icon: 'fail' });
          }
        })
      } else {
        this.pageState = status
      }
    },
    start() {
      var model = this.chatUserInfo.modelList[this.choicedModelIndex];
      var dayLimit = model.dayLimit
      var usedLimit = model.dayUsed
      if (dayLimit > 0 && usedLimit >= dayLimit)
        showFailToast({ message: '[' + model.modelName + '] 今日次数已用完，请更换模型', icon: 'fail' });
      else {
        var url = this.urlHead + '/chat/getAskNo'
        let _this = this
        this.request({ 'url': url, 'data': {} }).then(function (res) {
          if (res.code == 0) {
            _this.askNo = res.data
            _this.pageState = 'chat'
            var saveTitle = _this.text.length > 20 ? _this.text.substring(0, 20) : _this.text;
            var msg = { askNo: res.data, apiModel: _this.modelList[_this.choicedModelIndex].modelId, choiced: 1, msgTitle: saveTitle }
            _this.queryResult.list.splice(0, 0, msg)
            _this.choicedMsgIndex = 0
            _this.completions()
          } else {
            showFailToast({ message: res.data, icon: 'fail' });
          }
        })
      }
    },
    changeModel(modelIndex) {
      for (var i = 0; i < this.chatUserInfo.modelList.length; i++) {
        this.chatUserInfo.modelList[i].choiced = 0;
      }
      this.chatUserInfo.modelList[modelIndex].choiced = 1
      this.choicedModelIndex = modelIndex
    },
    getImgs(name) {
      return require('@/assets/model_icon_' + name + '.png')
    },
    getAskNo() {
      var url = this.urlHead + '/chat/getAskNo'
      let _this = this
      this.request({ 'url': url, 'data': {} }).then(function (res) {
        if (res.code == 0) {
          _this.askNo = res.data
        } else {
          showFailToast({ message: res.data, icon: 'fail' });
        }
      })
    },
    clearAllStateChanged() {
      if (this.replyingTimer != null)
        clearInterval(this.replyingTimer);
      this.lastReplyedAnswer = ''
      this.replyingTimes = 0
      this.autoStop = false
    },
    completions() {
      if (!this.generating) {
        this.clearAllStateChanged()
        const _this = this
        var url = this.urlHead + '/chat/completions'

        var from = 0;
        if (this.from == 'ww')
          from = 1;
        else if (this.from == 'pm')
          from = 2;
        var postObj = {
          'no': this.askNo,
          'msg': this.text,
          'model': this.modelList[this.choicedModelIndex].modelId,
          'od': this.deviceModel,
          'from': from
        };
        let sse = new SSE(url, {
          withCredentials: true,
          dataType: "text/event-stream",
          headers: {
            'Content-Type': 'application/json',
            'name': this.chatUser.name,
            'key': this.chatUser.key
          },
          payload: JSON.stringify(postObj)
        })

        var msg = { role: 1, msg: this.text, msgType: 1, html: '' }
        this.msgList.push(msg)
        var msg2 = { role: 2, msg: '', msgType: 1, html: '' }
        this.msgList.push(msg2)
        this.scrollToEnd();
        this.text = ""
        this.generating = true;
        sse.addEventListener('open', function (res) {
          console.log(res)
        })
        sse.addEventListener('message', function (result) {
          var res = JSON.parse(result.data)
          if (res.code == 0) {
            var data = res.data;
            if (data.end) {
              _this.generating = false
              let last = _this.msgList[_this.msgList.length - 1];
              last.html = marked.marked(last.msg)
              document.querySelectorAll('code').forEach((block) => {
                if (!block.classList.contains('hljs')) {
                  block.classList.add('hljs')
                }
              });
              sse.close()
              console.log('end')
            } else {
              let last = _this.msgList[_this.msgList.length - 1];
              var lineMsg = data.message;
              var html = last.msg + lineMsg;
              last.msg = html
              last.html = marked.marked(html)
              document.querySelectorAll('code').forEach((block) => {
                if (!block.classList.contains('hljs')) {
                  block.classList.add('hljs')
                }
              });
              _this.scrollToEnd();
              if (_this.generating == false) {
                sse.close()
              }
            }
          } else {
            _this.showError(res)
          }
        })
        sse.addEventListener('error', function (res) {
          _this.showError(res)
        })
        sse.stream()
        this.replyingTimer = setInterval(this.replyingAutoClose, 1000);
      }
    },
    replyingAutoClose() {
      if (this.generating) {
        if (this.replyingTimer != null) {
          if (this.replyingTimes >= 10) {
            this.stop();
            this.text = this.msgList[this.msgList.length - 2].msg
            this.autoStop = true;
            if (this.replyingTimer != null)
              clearInterval(this.replyingTimer);
          } else {
            if (this.lastReplyedAnswer == this.msgList[this.msgList.length - 1].msg)
              this.replyingTimes++;
            else {
              this.lastReplyedAnswer = this.msgList[this.msgList.length - 1].msg;
              this.replyingTimes = 0;
            }
          }
        }
      } else {
        if (this.replyingTimer != null)
          clearInterval(this.replyingTimer);
      }
    },
    reSend() {
      this.completions()
    },
    showError(res) {
      if(res.msg||res.data){
        if (res.msg == '今日用量已超过限制') {
            showFailToast({ message: '今日用量已超过限制', icon: 'fail' });
            this.changePageStatus('start')
        } else {
            var msgObj = JSON.parse(res.msg)
            if (msgObj) {
            var code = msgObj.error.code;
            if (code == "context_length_exceeded") {
                showFailToast({ message: '对话已超出限制，请开启新对话', icon: 'fail' });
            } else if (code == "model_not_found") {
                showFailToast({ message: '模型不存在，请重新开启对话', icon: 'fail' });
            } else {
                showFailToast({ message: '遇到错误，请开启新对话或联系管理员', icon: 'fail' });
            }
            } else {
            showFailToast({ message: '遇到错误，请开启新对话或联系管理员', icon: 'fail' });
            }
        }
    }else{
        if(res.indexOf('Websocket session is idle for too long time')>-1){
            showFailToast({ message: '没有声音，请重新开始', icon: 'fail' });
            this.recorder.stop();
        }else{
            showFailToast({ message: res, icon: 'fail' });
        }
    }
    },
    keyupSubmit() {
      document.onkeydown = e => {
        let _key = e.keyCode;
        if (e.ctrlKey && _key === 13) {
          if (this.text) {
            if (this.pageState == 'chat')
              this.completions();
            else
              this.start();
          }
        }
      }
      document.onresize = e => {
        this.sizeChanged()
      }
    },
    sizeChanged() {
      if (this.deviceModel == 1) {
        var width = window.innerWidth;
        if (width < 1000)
          this.msgWidth = '700px'
        else
          this.msgWidth = '85%';
      }
    },
    onUnload() {
      window.beforeunload = e => {
        this.test()
      }
    },
    contentBeauty(content) {
      // content = content.replace("\n\n","<p></p>")
      // content = content.replace("\n","<br/>")
      return content
    },
    scrollToEnd() {
      this.$nextTick(function () {
        var container = this.$refs.divMsgList
        var scrollToTop = container.scrollHeight;
        container.scrollTo({
          top: scrollToTop
        });
      });
    },
    afterExcelRead(file) {
      let _this = this;
      const isLt5M = file.file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        showFailToast({ message: '上传文件大小不能超过 10MB!', icon: 'fail' });
        return false;
      }
      var suffix = get_suffix(file.file.name);
      var g_object_name = calculate_object_name(file.file.name);

      file.status = 'uploading';
      file.message = '上传中...';
      file.file.ossKey = g_object_name;

      var request = new FormData();
      request.append('file', file.file);
      request.append('apiModel', this.modelList[this.choicedModelIndex].modelId)

      axios({
        method: 'post',
        url: this.urlHead + '/adv/uploadFile',
        data: request,
        headers: {
          'name': this.chatUser.name,
          'key': this.chatUser.key
        }
      }).then(function (res) {
        console.log(res)
      });
    }
  }
};

function getType(name) {
  var lidx = name.lastIndexOf('.');
  return name.substr(lidx);
}

function random_string(len) {
  len = len || 32;
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  var maxPos = chars.length;
  var pwd = '';
  for (var i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

function get_suffix(filename) {
  var pos = filename.lastIndexOf('.')
  var suffix = ''
  if (pos != -1) {
    suffix = filename.substring(pos)
  }
  return suffix;
}

function calculate_object_name(filename) {
  var suffix = get_suffix(filename);
  var g_object_name = random_string(15) + suffix;
  return g_object_name;
}

function get_uploaded_object_name(filename) {
  return g_object_name;
}


function initAudio(){
    // try{
    // if(audio==null){
    //     audio = new Audio();
    //     audio.src="";
    //     audio.play();
    // }}catch(es){}
}
</script>