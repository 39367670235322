// 首先导入 axios
import axios from 'axios'
import user from '@/utils/user.js'

// 自己创建一个axios对象
const request = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000,
    method: 'POST'
})

request.interceptors.request.use(config => {
    var chatUser = user.getUser()
    if (chatUser) {
        config.headers['name'] = chatUser.name
        config.headers['key'] = chatUser.key
    }
    return config
        // eslint-disable-next-line space-before-function-paren
}, function(error) {
    return Promise.reject(error)
})

request.interceptors.response.use(response => {
    const res = response.data
    if (res.code < 0) {
        // alert(res.msg ? res.msg : res.data)
    }
    return res
        // eslint-disable-next-line space-before-function-paren
}, function(error) {
    return Promise.reject(error)
})

export default request