import { createRouter, createWebHashHistory } from "vue-router";
// 1. 导入路由组件
import Home from "./views/Home.vue";
import About from "./views/About.vue";
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
const routes = [
    { path: "/", component: Home },
    { path: "/about", component: About }
];
// 3. 创建路由实例并传递 `routes` 配置
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
});
// 5. 配置路由对外接口
export default router;